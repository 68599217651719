<template>
  <v-icon
    small
    class="mx-1 cursor-pointer"
    :color="color"
    @click.stop="enabled = !enabled"
    >{{ enabled ? "mdi-flag-variant" : "mdi-flag-variant-outline" }}</v-icon
  >
</template>

<script>
export default {
  props: {
    value: {
      default: false,
    },
    color: {
      default: "red",
    },
  },
  computed: {
    enabled: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
};
</script>